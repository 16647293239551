import { Component } from '@angular/core';
import { COMMON_MESSAGE } from 'src/app/shared/constants/common.const';

@Component({
  selector: 'app-error',
  template: `
    <i class="error__icon uil uil-dollar-alt"></i>
    <div class="error__content">
      <h6 class="error__content-title" *ngIf="!isUnderMaintenance">{{commonMessage.SOMETHING_WENT_WRONG}}</h6>
      <p>{{isUnderMaintenance?commonMessage.MAINTENANCE_MESSAGE_1 + timePeriod +
        commonMessage.MAINTENANCE_MESSAGE_2:commonMessage.FETCHING_ERROR_MESSAGE}}</p>
    </div>
  `,
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  isUnderMaintenance: boolean = false;
  timePeriod: string = '11pm EST on Friday 2/16 to 11pm EST on Sunday 2/18';
  commonMessage = COMMON_MESSAGE;
}
